import { render, staticRenderFns } from "./post.vue?vue&type=template&id=32109c56&scoped=true"
import script from "./post.vue?vue&type=script&lang=js"
export * from "./post.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32109c56",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/hse-web-227/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32109c56')) {
      api.createRecord('32109c56', component.options)
    } else {
      api.reload('32109c56', component.options)
    }
    module.hot.accept("./post.vue?vue&type=template&id=32109c56&scoped=true", function () {
      api.rerender('32109c56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/post.vue"
export default component.exports