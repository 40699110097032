<template>
  <div>
    <!-- 头部样式 左侧为功能title  右侧为业务按钮 -->
    <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.plat.sys.post.title.indexHeadTitle')" @head-add="getNew"
      @head-romve="headRomve" @head-tabble-add="getthNew" @head-tree="getbsNew" @head-tabs="gettabsNew"
      @head-import="importFile" @head-export="exportFile"></head-layout>

    <!-- 对表单数据的搜索样式 -->
      <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
        @grid-head-search="searchChange" @grid-head-empty="searchReset">
      </grid-head-layout>
    <!-- 表格 -->
    <grid-layout ref="gridLayOut"
                 :tableOptions="tableOptions"
                 :table-data="dataParent"
                 :table-loading="loading"
                 :data-total="dataTotal"
                 :page="pageParent"
                 @page-size-change="onLoadParent"
                 @page-current-change="onLoadParent"
                 @page-refresh-change="onLoadParent"
                 @gird-handle-select-click="selectionChange"
                 :gridRowBtn="gridRowBtn"
                 @grid-edit="rowUpdate"
                 @grid-romve="rowDel">

    </grid-layout>
    <!-- 抽屉页面 编辑新增 -->
    <el-drawer class="el-drawer__wrapper avue-dialog avue-crud__dialog" :visible.sync="isShow" size="70%"
      style="margin-top: 50px;" :show-close="false">
      <template slot="title">
        <dialog-head-btn @dialog-head-save-click="save" @dialog-head-cancel-click="cancel" head-title="" icon="">
        </dialog-head-btn>
      </template>
      <form-layout v-if="isShow" :column="tableOptions.column" :dataForm="dataObj" ref="formLayout"></form-layout>
    </el-drawer>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {
  getList,
  remove,
  update,
  add,
} from "@/api/system/post";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "../components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout';
import {getSysConfig} from "@/api/system/user";
import { mapGetters } from "vuex";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
  },
  name: "show",
  data() {
    return {
      dataObj: '',
      tenantStatus:"",
      searchColumns: [

        {
          label: "",
          prop: "category",
          span:4,
          type: "select",
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.post.field.category"),
          dicUrl: "/api/sinoma-system/dict/dictionary?code=post_category",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          search: true,
        },
        {
          label: "",
          type: "input",
          prop: "postCode",
          span:4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.post.field.postCode"),

        },
        {
          label: "",
          type: "input",
          prop: "postName",
          span:4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.post.field.postName"),
        },
        {
          label: "",
          type: "select",
          prop: "isEnable",
          span:4,
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.post.field.isEnable"),
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          search: true,
        },

      ],
      dataTotal: 0,
      searchForm: {},//搜索数据
      dictValue: '暂无',//字典值
      parentId: -1,
      selectionList: [],
      box: false,
      loading: true,
      // 底部分页
      pageParent: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        pageSizes: [10, 20,30, 40, 50, 100],
        currentPage: 1,
        total: 0
      },
      // 看avue文档 以及 具体业务需求增加option数据
      // 要点 height 必须为auto  calcHeight 为300, 在这个条件下做的样式重绘
      // avue table 上侧的按钮栏通过样式将其隐藏（暂) 下面三个按钮需要做false处理
      // refreshBtn: false,
      // searchShowBtn: false,
      // columnBtn: false,
      //
      dataParent: [],
      tableOptions: {
        dialogDirection: 'rtl',
        dialogType: 'drawer',
        dialogClickModal: true,
        tip: false,
        searchShow: false,
        searchMenuSpan: 10,
        index: true,
        selection: true,
        viewBtn: false,
        menuWidth: 100,
        addRowBtn: true,
        dialogWidth: 880,
        refreshBtn: false,
        searchShowBtn: false,
        columnBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.post.field.tenantId"),
            prop: "tenantId",
            // dicUrl: "/api/sinoma-system/tenant/select",
            // props: {
            //   label: "tenantName",
            //   value: "tenantId"
            // },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=tenantType",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            align: "right",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.post.field.category"),
            prop: "category",
            type: "select",
            align:'center',
            dicUrl: "/api/sinoma-system/dict/dictionary?code=post_category",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.post.field.postCode"),
            prop: "postCode",
            align: "right",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.post.field.postName"),
            prop: "postName",
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.post.field.sort"),
            prop: "sort",
            type: "number",
            align: "right",
            width: 100,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.post.field.remark"),
            prop: "remark",
            type: "textarea",
            align: "left",
            hide:true,
            overHidden:true
          },
          {
            label: this.$t("cip.plat.sys.post.field.isEnable"),
            prop: "isEnable",
            type: "select",
            align:'center',
            dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: 'string',
            width: 100,
            overHidden:true
          },
        ]
      },
      isShow: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      console.log(this.permission, 'sdfsdf');
      return {
        addBtn: this.vaildData(this.permission.dict_add, false),
        delBtn: this.vaildData(this.permission.dict_delete, false),
        editBtn: this.vaildData(this.permission.dict_edit, false),
        viewBtn: false,
      };
    },
    headBtnOptions() {
      let   buttonBtn = [];
      if (this.permission.post_add) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            type: "button",
            remark: 'post_add',
            icon: ""
          },);
      }

      if (this.permission.post_delete) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            remark: 'post_delete',
            icon: ""
          });
      }
      return buttonBtn;
    },

    // 行按钮添加 add by steve
    gridRowBtn() {
      let   buttonBtn = [];
      if (this.permission.post_edit) {
        buttonBtn.push(
          {
            label:this.$t("cip.cmn.btn.editBtn"),
            emit: "grid-edit",
            type: "text",
            remark: 'post_edit',
            icon: ""
          });
      }
      if (this.permission.post_delete) {
        buttonBtn.push(
          {
            label:this.$t("cip.cmn.btn.delBtn"),
            emit: "grid-romve",
            type: "text",
            remark: 'post_delete',
            icon: ""
          })
      }
      return buttonBtn;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  methods: {
    getSysConfig(){
      getSysConfig().then(res=>{
        let data =res.data.data
        this.tenantStatus = data.is_tenant;
      })
    },
    save() {

      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then(() => {
            this.onLoadParent(this.pageParent);
            this.dataObj = ''
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.isShow = false
            // done();
          }, error => {
            window.console.log(error);
            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })

    },
    cancel() {
      this.isShow = false;
      this.dataObj = ''
    },
    getNew() {
      this.$router.push({
        path: '/post/postEdit',
        query:{
          type: 'add',
          tenantStatus: this.tenantStatus
        },
      })
      // this.isShow = true;

    },
    headRomve() {
      this.handleDelete()
    },
    getthNew() { },
    getbsNew() { },
    gettabsNew() { },
    importFile() { },
    exportFile() { },
    rowSave(row, done, loading) {
      console.log('rowSave')
      const form = {
        ...row,
        dictKey: -1,
      };
      add(form).then(() => {
        this.onLoadParent(this.pageParent);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
        // done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },

    rowUpdate(row) {
      this.$router.push({
        path: '/post/postEdit',
        query: {
          type: 'edit',
          id:row.id,
          tenantStatus: this.tenantStatus
        }
      })
    },
    rowDel(row) {

      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoadParent(this.pageParent);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    searchChange(params) {
      this.pageParent.currentPage = 1;
      this.onLoadParent(this.pageParent,params);
    },
    searchReset() {
      // console.log(this.$refs.searchFrom.searchForm = '')

      this.onLoadParent(this.pageParent)
    },
    selectionChange(list) {
      console.log(list)
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];

    },
    // 删除操作
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoadParent(this.pageParent);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });

        });
    },
    currentChange(currentPage) {
      this.pageParent.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.pageParent.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoadParent(this.pageParent, this.searchForm);
    },
    onLoadParent(page, params = {}) {
      this.pageParent = page;
      console.log(page)
      this.loading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.searchFrom.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.dataParent = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },

  },
  mounted() {
    this.getSysConfig()
    this.onLoadParent(this.pageParent)
  }
}
</script>

<style lang="scss" scoped>
</style>


